
.generate-certificate{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;
    .certificate-content {
        height: 100%;
        background: #fff;

        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .certificate-content-item{
            padding: 0 60px;
            margin-top: 20px;
            .certificate-info {
                display: flex;
                justify-content: flex-start;
                ::v-deep .el-input__inner {
                    height: 40px;
                    line-height: 40px;
                }
                ::v-deep .el-form-item__label {
                    color: #333;
                    font-size: 18px;
                }

                .certificate-form-left {
                    margin-right: 90px;
                }
                .certificate-form-cod {
                    display: flex;

                    ::v-deep .el-form-item__label {
                        width: 330px !important;
                    }
                    ::v-deep .el-form-item__content{
                        margin-left:0 !important;
                    }
                }
                ::v-deep .el-input-number {
                    .el-input__inner {
                        text-align: left;
                    }
                }
                /*.certificate-form-right-en{*/
                /*    ::v-deep .el-form-item__label{*/
                /*        width: 135px!important;*/
                /*    }*/
                /*    ::v-deep .el-form-item__content{*/
                /*        margin-left: 135px!important;*/
                /*    }*/
                /*}*/
            }
        }
        .btn-footer{
            text-align: center;
            margin: 20px 0;
        }
    }
}

